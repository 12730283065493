import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Hero from "../components/hero"
import ArrowRight from "../components/svg/arrowRight"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import Cta from "../components/cta"

const MadPage = () => (
    <Layout>
        <Seo 
            title="Web design and development case study" 
            description="Case study of the web design and Gatsby web development project completed by Launch Lab for Australian media agency Madclarity"
        />

        <Hero
            h1="Web design and illustration case study"  
            h2="Madclarity"
        />

        <section className="bg-noise madclarity section-pad-sm">
            <div className="container-lg" id="anchor">
                <div className="row">
                    <StaticImage 
                        src="../images/madclarity-main.png"
                        alt="Screens of the Madclarity website"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        className="porti-top-img"
                        placeholder="none"
                        backgroundcolor="white"
                        layout="fullWidth"
                        quality="100%"
                    />
                </div>
            </div>
        </section>

        <section className="section-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="porti-wrapper">
                        <div className="cols">
                            <div className="col-26">
                                <div className="sticky-porti-menu">
                                    <button onClick={() => scrollTo('#bg')} className="btn">
                                        Background
                                    </button>
                                    <button onClick={() => scrollTo('#services')} className="btn">
                                        Services
                                    </button>
                                    <button onClick={() => scrollTo('#work')} className="btn">
                                        The work
                                    </button>
                                    <button onClick={() => scrollTo('#project')} className="btn">
                                        View project
                                    </button>
                                </div>
                            </div>
                            <div className="col-67 long-text">
                                <div id="bg" className="porti-text-section">
                                    <h3>Background</h3>
                                    <p>Madclarity is a fast growing media consultancy in Australia.</p>
                                    <p>They work with leading national and multi-national brands and so required a new, more modern, website to provide better positioning in the market.</p>
                                    <p>The design brief was for a website that balanced 'fun' and 'professional' with a large fouc on having a clean and clear interface to leverage the 'clarity' positioning.</p>
                                </div>
                                <div id="services" className="porti-text-section">
                                    <h4>Services</h4>
                                    <Link to="/startup-web-designers/" className="btn btn-lg btn-line">Web design</Link>
                                    <span className="btn btn-lg btn-line">Website illustrations</span>
                                    <Link to="/gatsby-developer/" className="btn btn-lg btn-line">Gatsby.js</Link>
                                    <Link to="/web-development/" className="btn btn-lg btn-line">Web development</Link>
                                </div>
                                <div id="work" className="porti-text-section">
                                    <h4>The work</h4>
                                    <p>As their name suggests, the Madclarity brand guarantees clarity to their clients delivered in a fun yet professional way.</p>
                                    <p>The website needed to convey this and support the brand message.</p>
                                    <p>The result is a clean and professional website supported by fun illustrations which were drawn specifically for Madclarity by Launch Lab.</p>
                                    <p>Developed using Gatsby, the website loads rapidly and provides an excellent user experience and clear path to critical information.</p>
                                </div>
                                <div id="project" className="porti-text-section">
                                    <h5>View project</h5>
                                    <p>View the new Madclarity website.</p>
                                    <a href="https://madclarity.com/" className="btn btn-lg btn-purple">View website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>
        
        <Cta 
            title="Start a project"
            text="Get an obligation free web design or web development quote from an experienced local Australian development team."
        />
    </Layout>
)

export default MadPage